//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


	import { mapState } from 'vuex'
	import { mapMutations } from 'vuex'

	export default {
		scrollToTop: true,
		layout: 'default',
		transition: 'fade',
		head()
		{
			return this.head
		},
		data()
		{
			return{
				hero: {},
				head: {},
				blocks: {}
			}
		},

		 async fetch() {
			
			let self = this;
				const path = this.$route.path == '/' ? 'home' : this.$route.path.substring(1);

			let page = this.$store.state.directory[path];
 

			if (process.server || page != null)
			{

				if (page != null)
				{
				 
					this.$store.commit('page/setPageContent', page);   
					this.hero = page.hero;
					this.head = page.head;
					this.blocks = page.blocks;
				}
				else
				{
					console.log("server page is null", page == null);
 
					//this.$nuxt.error({ statusCode: 404, message: 'Page not found' });
				}
				
			}
			else if (page == null)
			{
				console.log("Using client api");
				


                self.$axios.$post('/.netlify/functions/content',
                    {
						query: "page('" + path + "')",
						select: {
							head: "page.title.toSeoHelper",
							breadcrumb: "page.title.toCrumbs",
							hero : "page.title.toHeroHelper",
							blocks: "page.blocks.toBlocksHelper",        
					}
				   }, {
                        baseURL: "",
                        auth: "",                                
                        headers: {
                            common: {
                            Authorization: ''
                            }
                        },
                        config: {
                            auth: ""
                        }
                    }
                ).then(resp => {

					 console.log("client response", resp);
				 	page = resp.data.result;


					if (page != null)
					{
					
						this.$store.commit('page/setPageContent', page);   
						this.hero = page.hero;
						this.head = page.head;
						this.blocks = page.blocks;
					}
					else
					{
						console.log("page is null", page == null);
						this.$nuxt.error({ statusCode: 404, message: 'Page not found' });
 
					}

				}).catch(function (error) {
					
					if (error.response) {
					// Request made and server responded
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
					} else if (error.request) {
					// The request was made but no response was received
					console.log(error.request);
					} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
					} 

					
					if (path != 'search')
					{
			 			return self.$nuxt.error({ statusCode: 404, message: error.message })
					}
				});

 
			}

				
		}

		
	}

